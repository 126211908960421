import React from 'react'
import * as Scroll from 'react-scroll';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from "gatsby"
import logo from '../../img/logos/bishop-homepage-logo.png';
import aboutUsLogo from '../../img/logos/bishop-aboutus-logo.png'
import whatWeDoLogo from '../../img/logos/bishop-what-we-do-logo.png'
import technologiesLogo from '../../img/logos/bishop-technologies-logo.png';
import styled from 'styled-components';

const FooterLinksUL = styled.ul`
	margin: 0; padding: 0; margin-top: 5px;
	@media(max-width: 991px) {
		margin-bottom: 25px;
	}
`;
const FooterLinksLI = styled.li`
	list-style: none; margin-bottom: 10px;
	@media(max-width: 991px) {
		text-align: center;
	}
`;
const FooterLinksA = styled(Link)`
	color: rgba(255,255,255,.8); font-size: 14px; line-height: 24px; cursor: pointer; text-transform: none; width: 100%; display: block; text-decoration: none; font-weight: 400;
	${FooterLinksLI}:hover & {
		color:#fff;
		text-decoration: none;
	}
	&:before {
		transition: all .3s ease-out;
		font-family: "Font Awesome 5 Free";
		font-weight: 900;
		content: "\f061";
		font-size: 12px;
		opacity: 0; line-height: 24px; left: -15px; position: absolute;
	}
	${FooterLinksLI}:hover &:before {
		transition: all .3s ease-out;
		opacity: 1;
		left: -10px;
	}
`;
const FooterLinksCategory = styled.h3`
	font-size: 16px; line-height: 24px; font-weight: 700; color: #444; margin-bottom: 10px;
	@media(max-width: 991px) {
		text-align: center;
	}
`;
const SocialIcon = styled.i`
	color: #fff; font-size: 20px; &:hover { color: rgba(255,255,255,.7);}
`;
const FooterLogo = styled.h2`
	color: #fff;
	margin: 0 0 20px 0;
	@media(max-width: 991px) {
		text-align: center;
	}
`
export default class extends React.Component {

    toTop = () => {
        Scroll.animateScroll.scrollToTop();
	}	
	
	getLogo = () => {
		switch (this.props.section) {
			case 'about-us':
				return aboutUsLogo;
			case 'what-we-do':
				return whatWeDoLogo;
			case 'technologies':
				return technologiesLogo;
			default:
				return logo;
		}
	}

    render() {
        return (
            <footer style={{backgroundColor: '#181e25', fontSize: '14px', lineHeight: '20px', color: '#fff', padding: '3.75rem 1.875rem', maxWidth: '100%'}}>
				<Container>
					<Row>
						<Col lg="4" xs="12">
							
							<FooterLogo style={{color: '#fff', margin: '0 0 20px 0'}}><img src={this.getLogo()} /> Bishop
								<span style={{fontSize: '22px'}}>software</span>
							</FooterLogo>
							<p style={{marginBottom: '25px'}}>
							Bishop Software is a full-cycle custom software development company located in VA, United States. Founded in 2013, we have evolved into a strong team of dedicated and trusted IT professionals ready to undertake all the web/application development related tasks that help you meet your business needs. We work with hundreds of companies around the world to build the products, libraries, and resources businesses need to succeed.
							</p>
							<a href="https://twitter.com/bishopsoftware" style={{marginRight: '15px'}}>
								<SocialIcon className="fab fa-twitter"></SocialIcon>
							</a>
							<a href="https://www.facebook.com/bishopsoft" style={{marginRight: '15px'}}>
								<SocialIcon className="fab fa-facebook"></SocialIcon>
							</a>
						</Col>
						<Col lg="2" xs="12"><br /><br /></Col>
						<Col lg="2" xs="12">
							<FooterLinksCategory>What We Do</FooterLinksCategory>
							<FooterLinksUL>
								<FooterLinksLI>
									<FooterLinksA to="/custom-software-development">Custom Software Development</FooterLinksA>
								</FooterLinksLI>
								<FooterLinksLI>
									<FooterLinksA to="/web-mobile-development">Web &amp; Mobile Development</FooterLinksA>
								</FooterLinksLI>
								<FooterLinksLI>
									<FooterLinksA to="/software-consulting">Software Consulting</FooterLinksA>
								</FooterLinksLI>
								<FooterLinksLI>
									<FooterLinksA to="/user-experience">User Experience &amp; Design</FooterLinksA>
								</FooterLinksLI>
							</FooterLinksUL>
						</Col>
						<Col lg="2" xs="12">
							<FooterLinksCategory>Technologies</FooterLinksCategory>
							<FooterLinksUL>
								<FooterLinksLI>
									<FooterLinksA to="/technologies">Key Technologies</FooterLinksA>
								</FooterLinksLI>
								<FooterLinksLI>
									<FooterLinksA to="/technologies/react-js-development">React.js</FooterLinksA>
								</FooterLinksLI>
								<FooterLinksLI>
									<FooterLinksA to="/technologies/dot-net-development">.NET</FooterLinksA>
								</FooterLinksLI>
								<FooterLinksLI>
									<FooterLinksA to="/technologies/node-js-development">Node.js</FooterLinksA>
								</FooterLinksLI>
								<FooterLinksLI>
									<FooterLinksA to="/technologies/php-development">PHP</FooterLinksA>
								</FooterLinksLI>
							</FooterLinksUL>
						</Col>
						<Col lg="2" xs="12">
							<FooterLinksCategory>About Us</FooterLinksCategory>
							<FooterLinksUL>
								<FooterLinksLI>
									<FooterLinksA to="/about-us/how-we-work">How We Work</FooterLinksA>
								</FooterLinksLI>
								<FooterLinksLI>
									<FooterLinksA to="/blog">Blog</FooterLinksA>
								</FooterLinksLI>
								<FooterLinksLI>
									<FooterLinksA to="/about-us/careers">Careers</FooterLinksA>
								</FooterLinksLI>
								<FooterLinksLI>
									<FooterLinksA to="/legal/privacy">Privacy Policy</FooterLinksA>
								</FooterLinksLI>
								<FooterLinksLI>
									<FooterLinksA to="/contact">Work With Us</FooterLinksA>
								</FooterLinksLI>
							</FooterLinksUL>
						</Col>
					</Row>
					<Row>
						<div style={{textAlign: 'center', width: '100%'}}>
							<hr style={{margin: '45px 0 0 0', border: 0, borderTop: '1px solid #353535'}}/>
							<Button type="button" color="primary" size="lg" onClick={this.toTop} style={{display: 'inline-block', position: 'relative', top: '-21px'}}>Up</Button>
							<p style={{margin: '15px 0 15px 0', color: '#fff'}}>2018 &copy; Bishop Software LLC</p>
						</div>
					</Row>
				</Container>
			</footer>
        )
    }
}
import React, {Component} from 'react'
import { Link } from "gatsby"
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

const HiringSection = styled.section`
    background-image: linear-gradient(175deg, #2b343f 0px, #151c23 80%, #0f151c 100%);
    padding: 50px 0;
`

const HiringCol = styled(Col)`
    text-align: center; color: white;
`
const HiringText = styled.span`
    font-size: 1.5em;
    line-height: 1.3em;
    @media(max-width: 770px) {
        display: block;
        padding-bottom: 25px;
    }
`
const HiringButton = styled(Link)`
    color: black !important; background-color: white !important; margin-left: 1.8em;
    &:hover {
        background-color: #ccc !important;
    }
`

export default class extends Component {

  render() {
    return (      
        <HiringSection>
            <Container>
                <Row>
                    <HiringCol>
                        <HiringText style={{fontSize: '1.5em'}}>We're always hiring great people.</HiringText>
                        <HiringButton to="/about-us/careers" className="btn round">View available roles</HiringButton>
                    </HiringCol>
                </Row>
            </Container>
        </HiringSection>
    );
  }
}

import React from 'react'
import { Link } from "gatsby"
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Helmet from 'react-helmet'
import { Container, Row, Col, Button, Form } from 'reactstrap'
import styled from 'styled-components'
import HiringSection from '../../components/Hiring'
import ContactForm from '../../components/ContactForm'
import FeatureWithImage from '../../components/FeatureWithImage'
import Layout from '../../components/Layout'

import phpImage1 from '../../img/pages/technologies/php/php-dev.png'
import wordPressImage1 from '../../img/pages/technologies/php/wordpress-1.png'

const CheckText = styled.div`
  position: relative; padding-left: 50px;padding-bottom:15px;line-height:1.8rem;
  &:before {
    position:absolute;
    color: #F192A7;
    left: 0;
    top: 5px;
    font-family: 'Font Awesome\\ 5 Free';
    font-weight: 900; display: inline-block; line-height: 1; font-style: normal;
    font-size:24px;
    content: '\\F00C';
  }
`

export default class extends React.Component {

  render() {
    return (
      <Layout>
      <div className="technologies">
        <Helmet
          title="PHP Software Development | Bishop Software"
          meta={[
            { name: 'description', content: 'We build secure and rich-functionality PHP web applications that will meet or exceed your digital demands and assist in your business growth. Let\'s talk.' },
            { name: 'keywords', content: 'php,laravel,symfony,apache,wordpress,software,development,web,design,mobile,consultancy,consulting,website,application' }
          ]}
        />
        <section style={{paddingTop: '75px', display: 'block', backgroundImage: 'linear-gradient(132deg, #09203A 0%, #207cca 73%, #6977B2 90%, #BC6591 100%)', color: '#fafafa'}}>
          <Header dropdownGradient='linear-gradient(#1076B8 0px, #072944 100%)' section="technologies" />
          <Container className="text-center" style={{paddingTop: '1.875rem'}}>
            <div style={{margin: '55px 0 0 0'}}>
              <h1 style={{color: 'rgb(243, 174, 189)', fontSize: '40px', fontWeight: 300}}>PHP Development Company</h1>
              <h3 style={{fontSize: '18px', marginTop: '35px', fontWeight: 400, lineHeight: '24px'}}>We build secure and rich-functionality PHP web applications that will meet or 
exceed your digital demands and assist in your business growth.</h3>
              <div style={{marginTop: '35px', paddingBottom: '55px'}}>
                <Link to="/contact" className="round btn btn-primary">Hire our PHP Developers</Link>
              </div>
            </div>
          </Container>
        </section>

    <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(180deg, #fff 0, #fff 30%, #fefefe 90%, #eee 100%)'}}>
      <Container>
        <Row>
          <Col>
          <p>At Bishop Software, our experienced development team is ready to provide reliable and scalable PHP web development services and solutions on a remote basis.</p>
          <p>PHP technology is a widely used open-source platform. We can consider it as a universal language for custom web development as well as for creating web portals. Today, the foremost popular social platforms and services, like Facebook, Flickr, Tumblr, Wikipedia and Digg are based on PHP web development. Additionally, a great number of web applications, like B2C, B2B and eCommerce apps are also designed using PHP.</p>
          <p>The main advantage of the PHP development is that website pages are generated quickly because scripts are processed on the server before being sent to the browser.</p>
          <p>The main difference of PHP as a platform from the others is that it can provide significant cost and time savings to any company.</p>
          </Col>
        </Row>
      </Container>
    </section>

    <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(180deg, #fff 0, #fff 30%, #fefefe 90%, #eee 100%)'}}>
      <Container>
        <Row>
          <Col>
            <FeatureWithImage title="Why PHP?" imageSrc={phpImage1}>
              <p>Every company and consumer want to have secure, stable, and effective web applications. To fulfill this demand, the Bishop Software has its own experienced and qualified PHP development team. One of the main reasons to choose PHP for web development is that it’s an open-source server-side scripting language that has various attributes and frameworks. That’s why it’s suitable to develop apps and services that meet the requirements of a wide range of industries. It also supports multiple platforms and application servers better than other competitive programming languages. Other advantages include the availability of excellent documentation, easy deployment, high flexibility, transparency, independence (platform can run on Windows, Linux or Mac servers), and high performance.</p>
              <p>PHP is widely used by a great number of web developers worldwide. It provides ample opportunities to create highly interactive and intuitive web applications and services that are simple in execution and have great security features.</p>
            </FeatureWithImage>
            <FeatureWithImage title="WordPress Development" imageSrc={wordPressImage1} right={true}>
              <p>WordPress has more than 68% share of the web industry. Today where there is a website for everything from booking movie tickets to shopping to playing games, WordPress has transformed the way business build and communicate with their customers. WordPress has emerged as the popular web content publishing platform in recent times. It adds beautiful appearance to a site while offering the best user experience.</p>
              <p>Bishop Software has used WordPress extensively for creating all kinds of websites ranging from small blogging websites to large websites with hundreds of web pages. Our experienced WordPress website developers have created many add-ons by utilizing the extensible nature of WordPress. Our WordPress developers not only create a website but also optimize it for the search engines. We enjoy innovating new tools for developing best possible websites for our clients.</p>
            </FeatureWithImage>
          </Col>
        </Row>
      </Container>
    </section>

    <section style={{backgroundImage: 'linear-gradient(179deg, #2c2f39 2%, #363944 14%, #32353d 100%)', color: '#ccc', padding: '70px 0'}}>
      <Container>
        <h2 className="text-center" style={{color: '#fff', marginBottom: '45px'}}>Core Advantages of PHP Development</h2>

        <Row>
          <Col xs="12" md="6">
            <CheckText><h4 className="text-white">Reducing web development cost</h4>
              PHP is not only free to download and use but it's compliant with all operating systems and runs on the most platforms (Windows, Linux, Unix, Mac OS X, etc.). It’s compliant with almost all servers like Apache, IIS, etc.
            </CheckText>
          </Col>
          <Col xs="12" md="6">
            <CheckText><h4 className="text-white">High level of security</h4>
              PHP has come a long way in the last few years and its modern libraries and frameworks provide the same top-notch level of security which is expected from 'enterprise' languages. Moreover, PHP allows for the creation of a hacker-proof website and web application, preventing malicious attacks and illegal entries.
            </CheckText>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6">
            <CheckText><h4 className="text-white">Easy execution of any project</h4>
              PHP-based business websites or web apps can be easily developed in a limited time and for a specific budget. This is possible thanks to its ability to support a wide range of databases and the availability of a great number of extensions and libraries.
            </CheckText>
          </Col>
          <Col xs="12" md="6">
            <CheckText><h4 className="text-white">Great choice of top quality PHP development teams</h4>
              The prevalence of this technology provides huge opportunities for hiring PHP developers from a reliable web development company, like Bishop Software. It’s far easier than finding other language programmers.
            </CheckText>
          </Col>
        </Row>
      </Container>
    </section>

    <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(176deg, #ddd 0, #fff 30%, #fff 90%, #f1f1f1 100%)'}}>
      <ContactForm />
    </section>

        <HiringSection />

        <Footer section="technologies" />
      </div>
  </Layout>
    )
  }
}
  
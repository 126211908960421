import React from 'react'
import { Container, Row, Col, Button, Form, FormFeedback } from 'reactstrap'
import styled from 'styled-components'
import querystring from 'querystring'
import Cookies from 'universal-cookie'
import NotificationSystem from 'react-notification-system'
import validate from 'validate.js'


const ContactFormInput = styled.input`
  display: block; width:100%; padding: .5em 1em;font-weight: 700; border-radius: 6px;
  border: 2px solid #666;
  &:focus {
    border: 2px solid #36afc4;
    outline: none;
  }
`
const ContactFormTextarea = styled.textarea`
  display: block; width:100%; padding: .5em 1em;font-weight: 700; border-radius: 6px;
  border: 2px solid #666;
  &:focus {
    border: 2px solid #36afc4;
    outline: none;
  }
`
const ContactFormButton = styled(Button)`
  background: #666 !important;
  &:hover {
    background: #444 !important;
  }
`

const validationConstraints = {
  name: {
    presence: {allowEmpty: false}
  },
  email: {
    email: true,
    presence: {allowEmpty: false}
  },
  project: {
    presence: {allowEmpty: false}
  }
}

const cookies = new Cookies()

export default class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '', email: '', phone: '', company: '', project: '', contactFormLoading: false, contactFormDone: false, contactFormSubmitted: false, validationResult: {}
    }
  }
  
  notificationSystem = null
  componentDidMount() {
    this.notificationSystem = this.refs.notificationSystem
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      // validate
      if(!this.state.contactFormSubmitted)
        return;
  
      const validationResult = validate({email: this.state.email, name: this.state.name, project: this.state.project}, validationConstraints)
      if(validationResult) {
        let removeArrayValidationResult = {}
        for(var k in validationResult) {
          if(!validationResult.hasOwnProperty(k)) continue;
          removeArrayValidationResult[k] = validationResult[k][0]
        }
        this.setState({validationResult: removeArrayValidationResult});
      } else {
        this.setState({validationResult: {}})
      }
    })
    
  }
  handleSubmit = e => {
    e.preventDefault()
    this.setState({contactFormSubmitted: true})

    // validate
    const validationResult = validate({email: this.state.email, name: this.state.name, project: this.state.project}, validationConstraints)
    if(validationResult) {
      let removeArrayValidationResult = {}
      for(var k in validationResult) {
        if(!validationResult.hasOwnProperty(k)) continue;
        removeArrayValidationResult[k] = validationResult[k][0]
      }
      this.setState({validationResult: removeArrayValidationResult});
      return;
    }

    this.setState({ contactFormLoading: true })

    let lead_source_description = "Page: " + window.location.href;

    // Set the campaign ID based on etrack
    // SEO campaign default
    let campaign_id = '96defcd3-2e9f-782a-dc6f-5ad248b496ac'
    if(cookies.get('etrk')) {
      switch(cookies.get('etrk')) {
        case 'fb-boost':
          campaign_id = 'b5b399ec-d8f4-c613-c73d-5ad3e58c5883'
          break
        case 'fb':
          campaign_id = '2a2748cc-c131-8f7c-1189-5ad3e6063fc6'
          break
        case 'g-soft':
          campaign_id = 'e1b19ef5-a40f-ec62-83c8-5ad3f45dd273'
          lead_source_description += "\nAdwords Campaign: " + cookies.get('campaignid') + "\nAdwords Ad Group: " + cookies.get('adgroupid')
          break
      }
    }

    let referrer = '';
    if(cookies.get('ref')) {
      referrer = cookies.get('ref')
    }

    // split the firstname and lastname
    const name = this.state.name.split(' ')
    const postData = querystring.stringify({
      email1: this.state.email,
      first_name: name[0],
      last_name: name[1],
      phone_work: this.state.phone,
      account_name: this.state.company,
      description: this.state.project,
      lead_source: "Web Site",
      lead_source_description: lead_source_description,
      refered_by: referrer,
      campaign_id: campaign_id,
      assigned_user_id: '1',
      moduleDir: 'Leads'
    })

    fetch('https://crm.bishopsoft.com/index.php?entryPoint=WebToPersonCapture', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
      mode: 'no-cors',
      body: postData
    }).then((data) => {
      this.notificationSystem.addNotification({
        title: 'We Got It!',
        message: 'Thank you for your interest in working with us. We will be in touch!',
        level: 'success',
        autoDismiss: 0,
        position: 'bc'
      })
      this.setState({ 
        contactFormDone: true,
        name: '', email: '', phone: '', company: '', project: ''
      })
    }).catch((err) => {
      this.notificationSystem.addNotification({
        title: 'Error',
        message: 'There was a problem submitting your request. Please email us: info@bishopsoft.com',
        level: 'error',
        autoDismiss: 0,
        position: 'bc'
      })
      this.setState({ contactFormLoading: false })
    })
  }

  getContactFormButtonText = () => {
    if(this.state.contactFormDone) {
      return (
        <i className="fas fa-check" />
      )
    }
    if(this.state.contactFormLoading && !this.state.contactFormDone) {
      return (
        <i className="fas fa-spinner fa-pulse" />
      )
    }
    return "Send"
  }
  render() {
    return (

      <Container>
        <Form onSubmit={this.handleSubmit}>
        <Row>
          <Col style={{textAlign: 'center'}}>
            <h2>Ok, let's talk...</h2>
            <hr />
            <h6 style={{lineHeight: '1.8rem'}}>Not a form person? If you'd prefer to email our team directly, you can reach us at <a href="mailto:info@bishopsoft.com">info@bishopsoft.com</a>.<br />Or call us at <a href="tel:18334247467">1-833-4-BISHOP</a> (1-833-424-7467).</h6>
          </Col>
        </Row>
        <Row style={{paddingTop: '35px'}}>
          <Col md="3" style={{textAlign: 'right', paddingTop: '10px'}}>
            <h5>Your name *</h5>
          </Col>
          <Col md="9">
            <ContactFormInput disabled={this.state.contactFormDone} style={{borderColor: this.state.validationResult.name ? '#d61747' : '#666'}} name="name" value={this.state.name} onChange={this.handleChange} />
            <FormFeedback style={{display: 'block'}}>{this.state.validationResult.name}</FormFeedback>
          </Col>
        </Row>
        <Row style={{paddingTop: '15px'}}>
          <Col md="3" style={{textAlign: 'right', paddingTop: '10px'}}>
            <h5>Your email *</h5>
          </Col>
          <Col md="9">
            <ContactFormInput disabled={this.state.contactFormDone} style={{borderColor: this.state.validationResult.email ? '#d61747' : '#666'}} name="email" value={this.state.email} onChange={this.handleChange} />
            <FormFeedback style={{display: 'block'}}>{this.state.validationResult.email}</FormFeedback>
          </Col>
        </Row>
        <Row style={{paddingTop: '15px'}}>
          <Col md="3" style={{textAlign: 'right', paddingTop: '10px'}}>
            <h5>Your phone number</h5>
          </Col>
          <Col md="9">
            <ContactFormInput disabled={this.state.contactFormDone} name="phone" value={this.state.phone} onChange={this.handleChange} />
          </Col>
        </Row>
        <Row style={{paddingTop: '15px'}}>
          <Col md="3" style={{textAlign: 'right', paddingTop: '10px'}}>
            <h5>Your company</h5>
          </Col>
          <Col md="9">
            <ContactFormInput disabled={this.state.contactFormDone} name="company" value={this.state.company} onChange={this.handleChange} />
          </Col>
        </Row>
        <Row style={{paddingTop: '15px'}}>
          <Col md="3" style={{textAlign: 'right', paddingTop: '10px'}}>
            <h5>Your project *</h5>
          </Col>
          <Col md="9">
            <ContactFormTextarea disabled={this.state.contactFormDone} style={{borderColor: this.state.validationResult.project ? '#d61747' : '#666'}} name="project" value={this.state.project} onChange={this.handleChange} />
            <FormFeedback style={{display: 'block'}}>{this.state.validationResult.project}</FormFeedback>
          </Col>
        </Row>
        <Row style={{paddingTop: '25px'}}>
          <Col style={{textAlign: 'center'}}>
            <ContactFormButton type="submit" className="round" disabled={this.state.contactFormLoading || this.state.contactFormDone}>
              {this.getContactFormButtonText()}
            </ContactFormButton>
          </Col>
        </Row>
        </Form>

        <NotificationSystem ref="notificationSystem" style={{
        NotificationItem: {
          DefaultStyle: {
            fontSize: '16px', padding: '20px'
          }
        },
        Title: {
          DefaultStyle: {
            fontSize: '24px', margin: '0 0 10px 0'
          }
        },
        Dismiss: {
          DefaultStyle: {
            width: '20px', height: '20px', top: '7px', right: '8px', fontSize: '24px', lineHeight: '20px'
          }
        }
      }} />
      </Container>
    )
  }
}
  
import React from 'react'
import { Helmet } from 'react-helmet'

import 'bootstrap/dist/css/bootstrap.min.css';
import '../font-awesome/css/fontawesome-all.min.css';
import '../font-awesome/font-mfizz-2.4.1/font-mfizz.css';
import '../css/core.css';
import Cookies from 'universal-cookie'

const cookies = new Cookies()

export default class extends React.Component{

  componentDidMount() {
    if(document && document.location.search) {
      let params = (new URL(document.location)).searchParams;
      if(params.get('etrk')) {
        cookies.set('etrk', params.get('etrk'))
        // set the referrer too
        cookies.set('ref', document.referrer)
      }
      // Google ValueTrack
      if(params.get('campaignid')) {
        cookies.set('campaignid', params.get('campaignid'))
      }
      // Google ValueTrack
      if(params.get('adgroupid')) {
        cookies.set('adgroupid', params.get('adgroupid'))
      }
    }
  }

  render() {
    return (
    <main>

      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#135880" />
        <meta name="msapplication-TileColor" content="#135880" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>

      {this.props.children}

    </main>
    )
  }
}

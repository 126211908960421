import React, {Component} from 'react'
import { Link } from "gatsby"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
Button} from 'reactstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import normalLogo from '../../img/logos/bishop-homepage-logo.png';
import aboutUsLogo from '../../img/logos/bishop-aboutus-logo.png';
import whatWeDoLogo from '../../img/logos/bishop-what-we-do-logo.png';
import technologiesLogo from '../../img/logos/bishop-technologies-logo.png';

const MobileNav = styled(Nav)`
  @media(max-width: 991px) {
    background-image: ${props => props.dropdownGradient};
  }
`

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  static propTypes = {
    dropdownGradient: PropTypes.string.isRequired,
    section: PropTypes.string
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  getLogo() {
    switch(this.props.section) {
      case 'about-us':
        return aboutUsLogo;
      case 'what-we-do':
        return whatWeDoLogo;
      case 'technologies':
        return technologiesLogo;
       default:
        return normalLogo;
    }
  }

  render() {
    return (      
        <Navbar dark color="faded" expand="lg" style={{height: '75px', position: 'absolute', maxWidth: '1000px', width: '100%', left: 0, right: 0, top: 0, marginLeft: 'auto', marginRight: 'auto'}}>
          <Link to="/" className="navbar-brand" style={{textDecoration: 'none', fontSize: '24px', color: '#fff'}}><img src={this.getLogo()} height="40" /> Bishop<span style={{fontSize: '18px'}}>software</span></Link>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <MobileNav className="ml-auto" navbar dropdownGradient={this.props.dropdownGradient}>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  What We Do
                </DropdownToggle>
                <DropdownMenu style={{backgroundImage: this.props.dropdownGradient}}>
                  <Link to="/custom-software-development" className="dropdown-item">
                    Custom Software Development
                  </Link>
                  <Link to="/web-mobile-development" className="dropdown-item">
                    Web &amp; Mobile Development
                  </Link>
                  <Link to="/software-consulting" className="dropdown-item">
                    Software Consulting
                  </Link>
                  <Link to="/user-experience" className="dropdown-item">
                    User Experience &amp; Design
                  </Link>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Technologies
                </DropdownToggle>
                <DropdownMenu style={{backgroundImage: this.props.dropdownGradient}}>
                  <Link to="/technologies" className="dropdown-item">
                    Key Technologies
                  </Link>
                  <DropdownItem divider />
                  <Link to="/technologies/react-js-development" className="dropdown-item">React JS</Link>
                  <Link to="/technologies/dot-net-development" className="dropdown-item">.NET</Link>
                  <Link to="/technologies/node-js-development" className="dropdown-item">Node.js</Link>
                  <Link to="/technologies/php-development" className="dropdown-item">PHP</Link>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  About Us
                </DropdownToggle>
                <DropdownMenu style={{backgroundImage: this.props.dropdownGradient}}>
                  <Link to="/about-us/how-we-work" className="dropdown-item">
                    How we work
                  </Link>
                  <Link to="/blog" className="dropdown-item">
                    Blog
                  </Link>
                  <Link to="/about-us/careers" className="dropdown-item">
                    Careers
                  </Link>
                  <DropdownItem divider />
                  <Link to="/contact" className="dropdown-item">
                    Contact Us
                  </Link>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem style={{marginLeft: '15px'}}>
                <Link to="/contact" className="btn btn-primary nav-link"><strong>WORK WITH US</strong></Link>
              </NavItem>
            </MobileNav>
          </Collapse>
        </Navbar>

        
    );
  }
}

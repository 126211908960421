import React, { Component } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Container, Row, Col, Collapse } from "reactstrap";

const H3Padding = styled.h3`
  margin-bottom: 25px;
  @media(max-width: 992px) {
    text-align: center;
  }
`
const TextCol = styled(Col)`
  order: ${props => props.right ? 1 : 0};
  @media(max-width: 992px) {
    order: 1;
  }
`
const ImageCol = styled(Col)`
  margin-bottom: 25px;
  text-align: ${props => props.right ? 'left' : 'right'};
  @media(max-width: 992px) {
    order: 0;
    text-align: center;
  }
`
const RowPadding = styled(Row)`
  margin-bottom: 35px;
`
export default class extends Component {

  render() {
    return (
      <RowPadding>
        <TextCol xs="12" lg="7" right={this.props.right}>
          <H3Padding>{this.props.title}</H3Padding>
          <p>{this.props.children}</p>
        </TextCol>
        <ImageCol xs="12" lg="5" right={this.props.right}>
          <img className="img-fluid" src={this.props.imageSrc} />
        </ImageCol>
      </RowPadding>
    );
  }
}
